import React, { Component } from "react";
import "../config";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';


class ForgetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userEmail: '',
        }
    }
    forgetPasswordChecking = (filterData) => {
        return new Promise((resolve, reject) => {
            fetch(global.config.api.remotePath + 'user/forget-password-check', {
                method: 'post',
                body: JSON.stringify(filterData),
                headers: {
                    'content-type': 'application/json'
                }
            }).then((res) => res.json())
                .then(data => {
                    if (data) {
                        resolve({ success: true, data: data })
                    } else {
                        resolve({ success: false, data: [] })
                    }
                }).catch(err => {
                    resolve({ success: false, err: err })
                })
        });
    }


    handleSubmit = async () => {
        $('.btn-block').attr('disabled', true);
        var submitData = [];
        submitData.push({ 'userEmail': this.state.userEmail, 'baseUrl': global.config.config.basePath });
        //console.log(submitData);
        await this.forgetPasswordChecking(submitData).then(data => {
            console.log('apistatus', data);
            // if (!data.success) {
            //     toast.error("Oops! Something went wrong");
            //     $('.btn-block').attr('disabled', false);
            // }
            if (data.data.status == 2) {
                toast.success("Password change request url send to your provided email address.");
                //window.location.href = '/admin-login';
                $('.btn-block').attr('disabled', false);
                this.setState({ userEmail: '' });
                $('.message_span').html("Password change request url send to your provided email address.");

            } else {
                if (data.data.error != null) {
                    toast.error(data.data.error);
                } else {
                    toast.error("Oops! Something went wrong");
                }
                $('.btn-block').attr('disabled', false);
            }
        });
    }
    render() {
        return (
            <div>
                <section>
                    <div class="wrapper">
                        <ToastContainer />
                        <div class="form-signin">
                            <h2 class="form-signin-heading">Forget password</h2>
                            <div class="form-group">
                                <label for="email">Email*</label>
                                <input type="email" class="form-control useremail" name="userEmail" placeholder="Email Address" required="" autofocus="" value={this.state.userEmail} onChange={(event) => this.setState({ userEmail: event.target.value })} />
                            </div>

                            <button onClick={() => this.handleSubmit()} class="btn log btn-block">Submit</button>
                            <span className="message_span"></span>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default ForgetPassword;